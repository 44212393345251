import { useCallback, useEffect, useState } from "react";

import ToDoFilters from "./ToDoFilters";
import ToDoList from "./ToDoList";

// store
import { useToDoStore } from "../store";

const ToDoWrapper = () => {

    const [items, setItems] = useState([]);
    const [filterSearch, setFilterSearch] = useState("");
    const [order, setOrder] = useState("desc");
    const { toDoItems } = useToDoStore();
    
    useEffect(() => {
        if(items && !items.length) {
            setItems(toDoItems);
        }
    }, [toDoItems]);

    useEffect(() => {
        setItems(toDoItems);
    }, [toDoItems]);
    
    const onSearchChange = (value) => {
        setFilterSearch(value);
    }

    const onOrderChange = (value) => {
        setOrder(value);
    }

    const searchedData = useCallback((items) => {
        console.log(items)
        if(filterSearch !== "") {
            console.log(filterSearch)
            const filteredValue = items.filter((item) => item.taskTitle.includes(filterSearch));
            console.log(filteredValue);
            setItems(filteredValue);
        } else {
            setItems(toDoItems);
        }
    }, [filterSearch, toDoItems])

    useCallback(() => {
        if(order === "asc") {
            items.sort((a,b) => {
                return new Date(b.createdAt) - new Date(a.createdAt);
            })
            const newArr = [...items];
            setItems(newArr);
        } else {
            items.sort((a,b) => {
                return new Date(a.createdAt) - new Date(b.createdAt);
            })
            const newDArr = [...items];
            setItems(newDArr);
        }
    }, [items, order, toDoItems])

    useEffect(() => {
        searchedData(items)
    }, [filterSearch])

    return (
        <>
            <ToDoFilters onSearchChange={onSearchChange} onOrderChange={onOrderChange} />
            {/* Todo List */}
            <ToDoList data={items} />
        </>
    )
}

export default ToDoWrapper;